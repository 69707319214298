<template>
  <div class="position-relative">
    <div v-if="$store.state.user.id" class="">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <!-- <router-link :to="{ name: 'Index' }" class="navbar-brand">Панель</router-link> -->
          <router-link :to="{ name: 'Index' }" class="navbar-brand">Теги</router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <!-- <router-link :to="{ name: 'Tags' }" class="nav-link">Теги</router-link> -->
              <router-link :to="{ name: 'Chats' }" class="nav-link">Чаты</router-link>
              <router-link v-if="$store.state.user.is_staff" :to="{ name: 'Accounts' }" class="nav-link">Аккаунты</router-link>
              <router-link v-if="$store.state.user.is_staff" :to="{ name: 'Bots' }" class="nav-link">Боты</router-link>
              <router-link v-if="$store.state.user.is_staff" :to="{ name: 'Labels' }" class="nav-link">Лейблы</router-link>
              <router-link v-if="$store.state.user.is_staff" :to="{ name: 'Users' }" class="nav-link">Пользователи</router-link>
              <router-link v-if="$store.state.user.is_staff" :to="{ name: 'Logs' }" class="nav-link">Логи</router-link>
              <!-- <a class="nav-link" href="#">Features</a>
              <a class="nav-link" href="#">Pricing</a> -->
            </div>
            <div class="navbar-nav ms-auto">
              <button @click="$store.dispatch('logout')" class="btn btn-outline-secondary">Выход</button>
            </div>
          </div>
        </div>
      </nav>
      <router-view/>
    </div>
    <div class="min-h-100-vh d-flex align-items-center justify-content-center" v-else>
      <Auth />
    </div>
    <Loading v-if="$store.state.loading"/>
  </div>
</template>

<script>
import Auth from './components/Auth'
import Loading from './components/Loading'
// import { useRoute } from 'vue-router'

export default {
  components: {
    Auth,
    Loading,
  },
  methods: {

  },
  async mounted () {
    try {
      console.log('App mounted')
      this.$store.dispatch('getUser')
    } catch (e) {
      this.$toasted.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss">
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

.bg-indigo {
  background-color: $indigo !important;
};
.bg-purple {
  background-color: $purple !important;
};
.bg-pink {
  background-color: $pink !important;
};
.bg-cyan {
  background-color: $cyan !important;
};

// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
// $white: #fff;
// $black: #000;
// $body-bg: $gray-900;
// $body-color: $white;
$card-bg: #eee;
// $input-border-color: $gray-800;
// $modal-content-bg: $black;
// $modal-header-border-color: $gray-900;
// $btn-close-color: $white;
// $nav-tabs-link-active-color: $white;
// $nav-tabs-link-active-bg: $body-bg;
// $nav-tabs-border-color: $gray-800;
// $nav-tabs-link-active-border-color: $gray-800;
// $nav-pills-link-active-bg: $gray-800;
// $table-border-color: $gray-700;

@import "~bootstrap/scss/bootstrap";
@import "@/assets/extra.scss";
@import "~vue-select/dist/vue-select.css";
</style>
