import axios from 'axios';

function getToken(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const api = {

  async getMe () {
    return axios.get(`/api/me`)
  },

  async login (context) {
    return axios.post('/api/login', {
      username: context.state.user.username,
      password: context.state.user.password,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async signup (context) {
    return axios.post('/api/signup', context.state.user, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async logout () {
    return axios.post('/api/logout', {}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },
}
