import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Tags.vue')
  },
  {
    path: '/labels',
    name: 'Labels',
    component: () => import('../views/Labels.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('../views/Accounts.vue')
  },
  {
    path: '/bots',
    name: 'Bots',
    component: () => import('../views/Bots.vue')
  },
  {
    path: '/accounts/:id',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/Chats.vue')
  },
  {
    path: '/chats/:id',
    name: 'Chat',
    component: () => import('../views/Chat.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('../views/Tags.vue')
  },
  {
    path: '/tags/:id',
    name: 'Tag',
    component: () => import('../views/Tag.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('../views/Logs.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth) {
    return {
      path: '/',
    }
  }
})

export default router
