// import Vue from 'vue'
import Vuex from 'vuex'
// import { useRoute } from 'vue-router'
// const route = useRoute()
// Vue.use(Vuex)
import {app} from './main'
import {api} from './api'
// import {router} from './main'
const store = new Vuex.Store({
  state: {
    user: {},
    auth: 'login',
    label: {},
    labels: [],
    labelModal: null,
    bots: [],

    accountsCount: 0,
    accounts: [],
    account: {},
    botOrigin: null,
    botOriginDialogs: [],
    sessions: [],
    dialogs: [],

    chats: [],
    chat: {},
    chatTab: 'statistic',
    chatModal: null,
    chatAccounts: [],
    chatSelectedMessages: [],

    tree: [],
    tags: [],
    tagModal: null,
    tagForm: {},
    tag: {
      tags: [],
      chats: [],
    },

    messagesSearch: {
      labels: false,
      text: null,
      dateFrom: null,
      dateTo: null,
      accountIds: [],
    },
    messages: [],
    messagesCount: 0,
    loading: true,

    users: [],
    usersItem: {},

    logsCount: 0,
    logs: [],
  },
  getters: {
    lastCardId (state) {
      if (state.list.length) {
        return state.list[state.list.length - 1].id
      } else {
        return null
      }
    }
  },
  mutations: {
    addSession (state, data) {
      console.log('store addSession', data)
      state.sessions.push(data)
    },
    updateSession (state, data) {
      const session = state.sessions.filter(s => s.id === data.id && s.origin === data.origin)[0]
      if (session) {
        session.authorized = data.authorized
        session.status = data.status
        session.busy = data.busy
      }
    },
    setUser (state, data) {
      state.user = data
    },
    mergeStore (state, data) {
      for (const key of Object.keys(data)) {
        state[key] = data[key]
      }
      if (data.chats && state.chatModal) {
        state.chatModal.hide()
      }
    },
    SOCKET_notif (state, data) {
      console.log('SOCKET_notif', data);
      if (data.status === 'error') {
        // app.$toast.error(data.message)
        app.$toast.error(data.message)
      } else {
        app.$toast.info(data.message)
      }
    },
    SOCKET_merge_store (state, data) {
      const keys = Object.keys(data)
      for (const key of keys) {
        state[key] = data[key]
      }
      if (data.chats && state.chatModal) {
        state.chatModal.hide()
      }
      if (data.labels && state.labelModal) {
        state.labelModal.hide()
      }
      if (data.tags && state.tagModal) {
        state.tagModal.hide()
      }
      if (data.messages) {
        const table = document.querySelector('#chat-messages')
        if (table) {
          table.scrollIntoView()
        }
      }
      state.loading = false
      app.$toast.info(`Обновлено`)
    },
    SOCKET_delete_item (state, data) {
      state[data.collection] = state[data.collection].filter(i => i.id !== data.id)
      app.$toast.info(`Удалено`)
    },
    SOCKET_delete_attachment (state, data) {
      state.card.attachments = state.card.attachments.filter(a => a.id !== data.id)
      app.$toast.info(`Удалено`)
    },
    SOCKET_add_item (state, data) {
      for (const key of Object.keys(data)) {
        // state[key].unshift(data[key])
        state[key].push(data[key])
      }
      if (data.labels && state.labelModal) {
        state.labelModal.hide()
      }
      if (data.tags && state.tagModal) {
        state.tagModal.hide()
      }
      if (data.chats && state.chatModal) {
        state.chatModal.hide()
      }
    },
    SOCKET_update_item (state, data) {
      const item = state[data.collection].filter(i => i.id === data.id)[0]
      for (const key of Object.keys(data.item)) {
        item[key] = data.item[key]
      }
      app.$toast.info(`Обновлено`)
    },
  },
  actions: {

    async signup (context) {
      try {
        const response = await api.signup(context)
        if (response.status === 200 && response.data.status === 'success') {
          context.state.auth = 'login'
          app.$toast.success('Успешная регистрация!', {duration: 2000})
          setTimeout(() => {
            location.replace('/')
          }, 2000)
        } else {
          app.$toast.error(response.data.message)
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async login (context) {
      try {
        const response = await api.login(context)
        if (response.status === 200 && response.data && response.data.id) {
          context.dispatch('getUser')
        } else if (response.data.status === 'not auth') {
          console.log('response.data')
          app.$toast.error('Не подходит логин/пароль')
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async getUser (context) {
      try {
        const response = await api.getMe(context.state)
        if (response.data) {
          context.commit('setUser', response.data)
          // if (response.data.id) {
          //   app.$socket.emit('get_data', ['chats', 'labels'])
          //   // app.$socket.emit('get_tags')
          // }
          // app.$socket.emit('get_data', ['tags', 'accounts', 'chats', 'labels'])
        }
      } catch (e) {
        // app.toasted.error(`${e.name}: ${e.message}`)
      }
    },

    async logout (context) {
      try {
        const response = await api.logout(context)
        if (response.status === 200) {
          context.commit('mergeStore', {user: {}})
          location.replace('/')
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async deleteSet ({ state, commit }) {
      if (confirm('Удалить набор?')) {
        try {
          const response = await api.deleteSet(state)
          if (response.status === 204) {
            commit('setData', {attr: 'set', value: {}})
            app.$toast.success(`Набор удален`)
            state.setModal.hide()
            app.$socket.emit('get_data', ['sets'], {})
          }
        } catch (e) {
          app.$toast.error(`${e.type}: ${e.message}`)
        }
      }
    },

    async deleteCategory ({ state, commit }) {
      if (confirm('Удалить категорию?')) {
        try {
          const response = await api.deleteCategory(state)
          if (response.status === 204) {
            commit('setData', {attr: 'category', value: {}})
            app.$toast.success(`Категория удалена`)
            state.categoryModal.hide()
            app.$socket.emit('get_data', ['categories', 'cards'], {})
          }
        } catch (e) {
          app.$toast.error(`${e.type}: ${e.message}`)
        }
      }
    },

  }
})

export {
  store
}
