import { createApp } from 'vue'
import App from './App.vue'
import {store} from './store'
import router from './router'
// import './registerServiceWorker'
import Toaster from '@meforma/vue-toaster';
import 'bootstrap'
import VueSocketIO from 'vue-3-socket.io'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

const app = createApp(App).use(router).use(store).use(Toaster).use(new VueSocketIO({
    debug: true,
    connection: window.location.origin,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    },
    options: {
      // path: "/my-app/"
    }
})).use(Vue3Lottie).use(VueVideoPlayer).mount('#app')

export {
  app
}
